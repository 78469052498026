import React from 'react';
import PropTypes from 'prop-types'
import PureComponent from '../pure';
import HomeWidgetHeader from './HomeWidgetHeader';
import HomeWidgetContent from './HomeWidgetContent';
import sAction from 'sAction';

/**
 * zobrazuje sloupce na HP, kde jsou dale boxy na budiky
 */
export default class HomeColumns extends PureComponent {
  render() {
    const way = this.props.way;
    const cols = this.props.data.cols.size;

    const render = [];

    //cyklus - pro kazdy sloupec dat na HP
    this.props.data.cols.forEach((col, colIndex) => {
      const lines = [];

      //cyklus - pro kazdy radek s budikem
      col.rows.forEach((row, rowIndex) => {
        if (!row || !row.def || !row.name) {
          return;
        }
        //drag and drop skryvani
        const style = {};
        style.display = row.def.get('show') ? "flex" : "none";

        //id grafu pro jeho stazeni
        let chartId = '';
        if (row.def.get('type') == 'chart' && row.data.get('source') == 'chartjs') {
          chartId = 'chart' + colIndex + 'r' + rowIndex;
        }

        //vyska widgetu
        let height = "450px";
        const options = row.data.getIn(["options", "height"]);

        if (options) {
          if (options.indexOf("px") !== -1 || options.indexOf("%") !== -1) {
            height = options;
            height = `calc(${height} - 14px)`; //14 px je margin mezi boxy
          }
          style.maxHeight = height;
        }

        //kdyz uzivatel vybere "automaticka velikost"

       style.minHeight = height;
       if(row.def.get("type") == "customReport"){
          style.height = height;
          if (sAction.param.is_portal) {
            style.height = '100%';
            style.minHeight = '100%';
            style.maxHeight = '100%';
          }
       }

        //pridani widgetu do pole
        lines.push(
          <div
            key={way + '/' + colIndex + '/rows/' + rowIndex}
            data-way={way + '/' + colIndex + '/rows/' + rowIndex}
            className={"homeCard homeDragAndDropItem"}
            style={style}
          >
            <HomeWidgetHeader //hlavicka
              name={row.name}
              way={way + '/' + colIndex + '/rows'}
              index={rowIndex}
              chartId={chartId}
              type={row.def.get('type')}
            />

            <HomeWidgetContent
              way={way + '/' + colIndex + '/rows/' + rowIndex}
              data={row.data}
              def={row.def}
              chartId={chartId}
              key={way + '/' + colIndex + '/rows/' + rowIndex + '/' + cols}
            />
          </div>
        );
      }); //konec cyklu s widgety

      //obaleni vsech radku s widgety do jednotlivych sloupcu
      render.push(
        <div
          key={way + '/' + colIndex}
          className="gridColumn homeDragAndDropContainer"
          data-way={way + '/' + colIndex + '/rows'}
        >
          {lines}
          <div className="homeDragAndDropItem" data-way="last" />
        </div>
      );
    });

    //zobrazeni vsech sloupcu a budiku v nich
    let ret = <div className={'gridContainer flex' + cols}>{render}</div>;

    /**
     * PORTAL
     */
    if (sAction.param.is_portal) {
      let columns = this.props.data.cols;

      let widget1 = this.getWidget(way + '/0/rows/0', columns.get(0).rows.get(0));
      let widget2 = this.getWidget(way + '/0/rows/1', columns.get(0).rows.get(1), "#list/acm_orders");
      let widget3 = this.getWidget(way + '/1/rows/1', columns.get(1).rows.get(1), "#list/acm_delivery_notes");
      let widget4 = this.getWidget(way + '/0/rows/2', columns.get(0).rows.get(2), "#customReports/8525b92f-69b0-4277-139c-60f167cad9ff");
      let widget5 = this.getWidget(way + '/1/rows/2', columns.get(1).rows.get(2), "#list/Documents/cat=ed2c67b4-9048-3e02-97c4-606ed8483b5c");

      ret = <React.Fragment>
        <div className={'gridContainer flex1'}>
          <div className="gridColumn">
            <div className="homeCard" style={{height: 345}}>{widget1}</div>
          </div>
        </div>
        <div className={'gridContainer flex2'}>
          <div className="gridColumn">
            <div className="homeCard">{widget2}</div>
          </div>
          <div className="gridColumn">
            <div className="homeCard">{widget3}</div>
          </div>
        </div>
        <div className={'gridContainer flex2'}>
          <div className="gridColumn">
            <div className="homeCard">{widget4}</div>
          </div>
          <div className="gridColumn">
            <div className="homeCard">{widget5}</div>
          </div>
        </div>
      </React.Fragment>;
    }

    return ret;
  }

  /*
  * PORTAL
  * */
  getWidget = (key, row, url = undefined) => {
    return <HomeWidgetContent
      way={key}
      key={key}
      data={row.data}
      def={row.def}
      name={row.name}
      url={url}
    />
  }
}

HomeColumns.propTypes = {
  data: PropTypes.shape({
    cols: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  way: PropTypes.string.isRequired // "home/tabs/" + activeId + "/cols"
}
